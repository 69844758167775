<template>
  <div class="main-container-dashboard">
    <v-row>
      <v-col cols="12" md="9" sm="9">
        <div class="dashboard-left">
          <div class="dashboard-left-header">
            <div class="title-left">
              <h1>Company</h1>
              <p>{{ $t("Navbar.welcome") }}</p>
            </div>
            <div class="date-now">
              {{ currentDateTime }}<span><i class="far fa-calendar"></i></span>
            </div>
          </div>
          <div class="card-left-group">
            <v-row>
              <v-col cols="12" md="4" lg="4" class="py-2">
                <div class="card-item-dashboard">
                  <div class="card-item-dashboard-detail">
                    <h3>{{ $t("Navbar.employee") }}</h3>
                    <p>{{ countAllEmployee }} {{$t("Navbar.people")}}</p>
                  </div>
                  <div class="card-item-dashboard-icon">
                    <i class="fas fa-user"></i>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4" lg="4" class="py-2">
                <div class="card-item-dashboard">
                  <div class="card-item-dashboard-detail">
                    <h3>{{$t("Navbar.absents")}}</h3>
                    <p>-- {{$t("Navbar.people")}}</p>
                  </div>
                  <div class="card-item-dashboard-icon">
                    <i class="fas fa-user"></i>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4" lg="4" class="py-2">
                <div class="card-item-dashboard">
                  <div class="card-item-dashboard-detail">
                    <h3>{{ $t("Navbar.leave") }}</h3>
                    <p>{{ countAllEmployeeAbsence }} {{$t("Navbar.people")}}</p>
                  </div>
                  <div class="card-item-dashboard-icon">
                    <i class="fas fa-pencil"></i>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="chart-group-item">
            <v-row>
              <v-col cols="12" md="6" lg="6">
                <div class="chart-item">
                  <column-chart :data="[
                    ['Sun', 32],
                    ['Mon', 46],
                    ['Tue', 28],
                  ]"></column-chart>
                </div>
              </v-col>
              <v-col cols="12" md="6" lg="6">
                <div class="chart-item">
                  <column-chart :data="[
                    ['Sun', 32],
                    ['Mon', 46],
                    ['Tue', 28],
                  ]"></column-chart>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="3" sm="3">
        <CompanyProfile />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CompanyProfile from "@/views/Dashboard/CompanyProfile";

export default {
  components: {
    CompanyProfile,
  },
  data() {
    return {
      countAllEmployee: "",
      countAllEmployeeAbsence: "",
      dateNow: "",
      EmployeeContractExpired: []
    };
  },
  methods: {
    fetchCountEmployee() {
      this.isLoading = true;
      this.$axios
        .get(`/company/list/count-employee`)
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.countAllEmployee = res.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    fetchCountEmployeeAbsence() {
      this.isLoading = true;
      this.$axios
        .get(`/company/list/count-employee-absence`)
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.countAllEmployeeAbsence = res.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    fetchDashboard() {
      // this.isLoading = true;
      // this.$axios
      //   .get(`/company/company-dashboard`)
      //   .then((res) => {
      //     if (res.status === 200) {
      //       this.EmployeeContractExpired = res.data.data.employee_contract_exprice;
      //     }
      //     this.isLoading = false;
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     this.isLoading = false;
      //   });
    },
  },
  computed: {
    currentDateTime() {
      const currentTime = this.$helpers.getDateTime();
      return `${currentTime.days} ${currentTime.months} ${currentTime.years}`;
    },
  },
  created() {
    this.fetchDashboard();
    this.fetchCountEmployee();
    this.fetchCountEmployeeAbsence();
  },
};
</script>

<style scoped lang="scss"></style>
